<template>
  <div class="home">
    <!-- <transition name="fade"> -->
    <router-view></router-view>
    <!-- </transition> -->
    <van-tabbar
      v-model="active"
      route
      z-index="1000"
      active-color="#333333"
      inactive-color="#999999"
    >
      <van-tabbar-item replace to="/huodong">
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" alt=""
        /></template>
        <span>社交活动</span>
      </van-tabbar-item>
      <van-tabbar-item icon="search" replace to="/tuodan"
        ><span>我要脱单</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o" replace to="/wode"
        ><span>个人中心</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" /> </template
      ></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      active: 0,
      icon1: {
        active: require("../assets/img/shejiao02.png"),
        inactive: require("../assets/img/shejiao.png")
      },
      icon2: {
        active: require("../assets/img/tuodan02.png"),
        inactive: require("../assets/img/tuodan.png")
      },
      icon3: {
        active: require("../assets/img/my02.png"),
        inactive: require("../assets/img/my.png")
      }
    };
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
